export const manager_menu = [
    {
        "name": "Dashboard",
        "url": "/members/dashboard",
        "icon": "lni lni-grid-alt",
        "icon_type": "lni",
        "alias": "dashboard",
        "auth_required": true,
        "user_type": "manager",
    },
    {
        "name": "Transactions History",
        "url": "/members/transactions",
        "icon": "fa fa-rotate-left",
        "icon_type": "fa",
        "alias": "transactions",
        "auth_required": true,
        "user_type": "manager",
    },
    {
        "name": "Notifications",
        "url": "/members/notifications",
        "icon": "fa fa-bell",
        "icon_type": "fa",
        "alias": "notifications",
        "auth_required": true,
        "user_type": "manager",
    },
    {
        "name": "DIGITAL BANKING",
        "children": [
            {
                "name": "Paycodes Payment",
                "url": "/members/paycodes",
                "icon": "fa fa-money-check",
                "icon_type": "fa",
                "alias": "paycodes",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Invoice",
                "url": "/members/invoices",
                "icon": "fa fa-receipt",
                "icon_type": "fa",
                "alias": "invoices",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "P2P",
                "url": "/members/p2p-payment",
                "icon": "fa fa-arrow-right-arrow-left",
                "icon_type": "fa",
                "alias": "p2p",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "FasePay",
                "url": "/members/fasepay",
                "icon": "fas fa-credit-card",
                "icon_type": "fa",
                "alias": "fasepay",
                "auth_required": true,
                "user_type": "manager",
                "is_new": true,
            },
            {
                "name": "Bills Payment",
                "url": "/members/bills-payment",
                "icon": "fa fa-receipt",
                "icon_type": "fa",
                "alias": "bills_payment",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Loyalty Voucher",
                "url": "/members/loyalty-voucher",
                "icon": "gifts",
                "icon_type": "fa",
                "alias": "loyalty-voucher",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Tax Calculator",
                "url": "/members/tax-calculator",
                "icon": "calculator",
                "icon_type": "fa",
                "alias": "tax-calculator",
                "auth_required": true,
                "user_type": "manager",
            },
        ]
    },
    {
        "name": "CUSTOMER MARKETING",
        "children": [
            {
                "name": "Customers",
                "url": "/members/customers",
                "icon": "lni lni-users",
                "icon_type": "lni",
                "alias": "customers",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "SMS Marketing",
                "url": "/members/messaging/sms",
                "icon": "fa fa-comment",
                "icon_type": "fa",
                "alias": "sms",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Email Marketing",
                "url": "/members/messaging",
                "icon": "lni lni-envelope",
                "icon_type": "lni",
                "alias": "email",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Announcements",
                "url": "/members/announcements",
                "icon": "fa fa-bullhorn",
                "icon_type": "fa",
                "alias": "announcements",
                "auth_required": true,
                "user_type": "manager",
            },
        ]
    },
    {
        "name": "INVENTORY",
        "children": [
            {
                "name": "Stock",
                "url": "/members/inventory",
                "icon": "fa fa-shop",
                "icon_type": "fa",
                "alias": "inventory",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Stock Request",
                "url": "/members/stock-request",
                "icon": "fa fa-box",
                "icon_type": "fa",
                "alias": "stock_request",
                "auth_required": true,
                "user_type": "manager",
            },
        ]
    },
]