import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useRef, useState } from "react";
import ngn from '../../assets/images/NGN.svg';
import usd from '../../assets/images/USA.svg';
import cad from '../../assets/images/CAD.svg';
import gbp from '../../assets/images/gbp.svg';
import rotateKnob from '../../assets/images/rotate.svg';
import DrewsModal from "../../components/modal/DrewsModal";
import { useDispatch, useSelector } from "react-redux";
import { checkBalance, createBulkPaycode, fxExchange, getFasepayTransactionHistory, getRates, getTransactionHistory, getTransferCharges, payWithFase } from "../../redux/slices/account.service";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import { getWallets, withdrawalRequest } from "../../redux/slices/users";
import dateFormatter from "../../utils/dateFormatter";
import fase from "../../assets/images/fasewallet.svg"
import { completeFasePayWidgetTour } from "../../redux/slices/auth";
import CurrencyDropdown from "../../components/CurrencyDropdown";
import RecordFilter from "../../components/RecordFilter";

const FasePay = () => {

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const dispatch = useDispatch();
    const { fasepay_widget } = useSelector((state) => state.auth)

    const [modalShow, setModalShow] = useState(false)
    const [showForm, setForm] = useState(false)
    const [show, setShow] = useState(false)
    const [showBalance, setShowBalance] = useState(false)
    const [payload, setPayload] = useState({
        to_currency: 'NGN', 
        currency: 'USD', 
        amount: 1,
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: 'FasePay',
    })
    const [transaction, setTransaction] = useState({})
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [genVoucher, setGenVoucher] = useState(false)
    const [paycodes, setPaycodes] = useState([])
    const [errors, setErrors] = useState({})
    const [paycode, setPaycode] = useState({})
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [page, setPage] = useState(1)
    const [fxForm, setFxForm] = useState(false)
    const [show_widget, setWidget] = useState(true)
    const [widget_state, setWidgetState] = useState('first')
    const [wallet, setWallet] = useState({})
    const [transactions, setTransactions] = useState([])
    const [rates, setRates] = useState([]);
    const [conversion, setRateConversion] = useState({});
    const [withdrawal, setWithdrawal] = useState(false);
    const [pinValue, setpinValues] = useState(['', '', '', ''])
    const [fee, setFee] = useState(0.00)
    const [charges, setSystemCharges] = useState(0)



    const usd_to_nara = rates.find((r) => r.currency === 'USD')
    const cad_to_nara = rates.find((r) => r.currency === 'CAD')


    useEffect(() => {
        loadWallets()
        loadRates()
        getSystemCharges()
    }, [0])

    useEffect(() => {
        convertCurrency()
    }, [payload])

    useEffect(() => {
        if(fasepay_widget) {
            setWidget(false)
        }
        loadTransactions()
    }, [payload.page])

    const selectTransaction = (item) => {
        setPaycode(item)
        setShow(true)
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            // Allow only numbers and a single decimal point
            // const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
            const newValue = value.replace(/,/g, '') //parseFloat(value.split(',').join(''))
            const formattedValue = newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') //formatCurrency(value).formatted

            setPayload((prevState) => ({ ...prevState, [name]: newValue }));
            setPayload((prevState) => ({ ...prevState, 'price': formattedValue }));
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }

        if(name === 'amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 0) {
            calculateFees()
        }
    }

    const createPayCode = () => {
        setLoading(true)
        dispatch(createBulkPaycode(payload))
        .then((e) => {
            // console.log("PAYCODE RES", e)
            // loadPaycodes(page)
        })
        .finally(() => setLoading(false))
    }

    const applyPaycode = () => {
        if(!payload.amount && !payload.paycode && !payload.secret_word && !payload.currency)  { return }

        setLoading(true)
        dispatch(payWithFase(payload))
        .then((response) => {
            setLoading(false)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => setForm(false))
            }
            else if(response.payload?.success === false || response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
    }

    const paycodeBalance = () => {
        if(!payload.paycode && !payload.secret_word)  {  return }
        setLoading(true)
        dispatch(checkBalance(payload))
        .then((response) => {
            if(response.payload?.success) {
                Swal.fire({
                    icon: 'info',
                    text: response.payload?.message
                })
                .then(() => setShowBalance(false))
            }
        })
        .finally(() => setLoading(false))
    }
    
    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const loadWallets = () => {
        dispatch(getWallets())
        .then((res) => { 
            if(res.payload?.length) {
                setWallet(res.payload[0])
            }
        })
    }

    const sellFx = () => {
        Swal.fire({
            icon: 'warning',
            text: 'Do you wish to proceed?',
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: 'No, Cance',
            confirmButtonText: 'Yes, Proceed'
        })
        .then((result) => {
            if(result.isConfirmed) {
                setLoading(true)
                dispatch(fxExchange(payload))
                .then((response) => {
                    if(response.payload?.success === true) {
                        setFxForm(false)
                        Swal.fire({
                            icon: 'success',
                            text: response?.payload?.message
                            // text: `An equivalent of ${formatCurrency(payload.amount, payload.currency).with_currency} (${formatCurrency(conversion.amount, payload.to_currency).with_currency}) has been credited to you`, //response?.payload?.message
                        })
                        .then(() => {
                            loadWallets()
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response?.payload?.message
                        })
                    }
                })
                .finally(() => setLoading(false))
            }
        })
    }


    const loadTransactions = () =>  {
        // dispatch(getFasepayTransactionHistory(payload))
        setLoading(true)
        dispatch(getTransactionHistory(payload))
        .then((response) => {
            if(response.payload.results) {
                setTransactions(response.payload.results)
            }

            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }

    const manageWidget = () => {
        setWidget(false)
        dispatch(completeFasePayWidgetTour())
    }

    const loadRates = () => {
        dispatch(getRates())
        .then((response) => {
            if(response.payload?.data) {
                setRates(response.payload.data)
            }
        })
    }

    const convertCurrency = () => {
        let converted = {}
        let from_currency = payload.currency
        let to_currency = payload.to_currency
        let amount = payload.amount
        let rate = rates.find((rate) => rate.currency === from_currency)
        let to_rate = rates.find((rt) => rt.currency === to_currency)
        if(rate) {
            converted = {
                amount: amount * rate.rate,
                currency: to_currency
            }
        }
        else if(from_currency === 'NGN') {
            converted = {
                amount: to_rate ? amount / to_rate.rate : amount * 1,
                currency: to_currency
            }
        }
        setRateConversion(converted)
    }

    const handleCurrencySelect = (event, name) => {
        setPayload({...payload, [name]: event.value})
    }

    const handleOtpInputs = (index, event) => {
        
        const value = event.target.value

        if(isNaN(value)) return;

        const pinValues = [...pinValue]
        pinValues[index] = value
        setpinValues(pinValues)

        if (value !== '' && index < 3) {
            inputRefs[index + 1].current.focus()
        }        
    }

    const sendWithrawalRequest = () => {
        if (!payload.amount) {return}
        if(!pinValue.length || pinValue.join("") === '' || pinValue.join("").length < 4) {
            setLoading(false)
            return;
        }

        setLoading(true)
        dispatch(withdrawalRequest({amount: payload.amount, pin: pinValue.join("")}))
        .then((response) => {
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    setWithdrawal(false)
                    loadWallets()
                })
            }
            else if(response.payload?.success === false) {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const calculateFees = ()  => {
        let amount = parseFloat(payload.amount);
        const percentage = (amount / 100) * charges
        const fee = percentage < 2500 ? percentage : 2500 
        setFee(fee)
        return fee
    }

    const getSystemCharges = () => {
        dispatch(getTransferCharges())
        .then(response => {
            if(response.payload !== 'undefined') {
                const data = response.payload
                if(data.length) {
                    const charge = data.find(item => item.name === 'withdrawal')
                    if(charge.value) {
                        setSystemCharges(charge.value)
                    }
                }
            }
        })
    }

    const handleSearch = () => {
        loadTransactions()
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="row align-items-center justify-content-between flex-wrapd">
                    <div className="col-md-4">
                        <p>
                            <h3 className="m-0">FasePay</h3>
                            <small>Receive Paycode from abroad</small>
                        </p>                        
                    </div>
                    <div className="col-md-8">
                        <Button 
                            type="button" 
                            variant="light" 
                            className="border-0 bg-white text-primary mb-2 px-3"
                            onClick={ () => setShowBalance(true)}
                        >
                            <FontAwesomeIcon icon={'eye'}/>
                            <span className="ps-2">Check balance</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="primary" 
                            className="border-0 mb-2 mx-2"
                            onClick={ () => setForm(true)}
                        >
                            <FontAwesomeIcon icon={'plus'} />
                            <span className="ps-2">Apply PayCode</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="primary" 
                            className="border-0 mb-2 mx-2 px-4"
                            onClick={ () => setWithdrawal(true)}
                        >
                            <FontAwesomeIcon icon={'print'} />
                            <span className="ps-2">Withdraw</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="light" 
                            className="text-primary bg---primary-faint border-0 mx-2 mb-2 w-25"
                            onClick={ () => setFxForm(true)}
                        >
                            <FontAwesomeIcon icon={'dollar-sign'} />
                            <span className="ps-2">Sell</span>
                        </Button>
                    </div>
                </Col>

                <div className="mt-4">
                    {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    }
                    <Row className="data-card mb-4">
                        <Col md={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted">Wallet Balance</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{formatCurrency(wallet.amount).with_currency || '0.00'}</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        <img src={ngn} alt="ngn" />
                                        Naira Wallet
                                    </span>
                                </Card.Body>
                            </Card> 
                        </Col>
                        <Col md={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted">Dollar Wallet Balance</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{ formatCurrency(wallet?.usd_amount, 'USD').with_currency }</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        <img src={usd} alt="usd" />
                                        USD Wallet
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted">Great Britain Pound Wallet Balance</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{ formatCurrency(wallet?.gbp_amount, 'GBP').with_currency }</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        <img src={gbp} alt="gbp" />
                                        GBP Wallet
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted">Canadian Dollar Wallet Balance</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{ formatCurrency(wallet?.cad_amount, 'CAD').with_currency }</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        <img src={cad} alt="cad" />
                                        CAD Wallet
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-between">
                        <div className="col-md-5">
                            <h5 className="mb-2">Transaction History</h5>
                        </div>
                        <div className="">
                            <RecordFilter 
                                handleInput={handleInput}
                                handleSearchSubmit={handleSearch}
                            />
                        </div>
                    </Row>

                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Body className="table-responsive-lg">
                        <Table size="sm" className="table-color table-stripedd">
                                <thead>
                                    <tr>
                                        <th>Details</th>
                                        <th>Order</th>
                                        <th>Amount</th>
                                        <th>Balance Before</th>
                                        <th>Balance After</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactions?.length ? (
                                            transactions?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="m-0 d-flex gap-2 align-items-center">
                                                            <small className={item.status === 'success' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                <i className="lni lni-arrow-top-right"></i>
                                                            </small>
                                                            <strong>
                                                                <p className="m-0">{item.note}</p>  
                                                                <small>{dateFormatter(item.date)}</small>
                                                            </strong>
                                                        </div>
                                                    </td>
                                                    <td> {item.order}  </td>
                                                    <td>{formatCurrency(item.amount, item.currency).with_currency}</td>
                                                    <td>{formatCurrency(item.amount_before, item.currency).with_currency} </td>
                                                    <td>{formatCurrency(item.amount_after, item.currency).with_currency} </td>
                                                    <td>
                                                        <p className="m-0 d-flex align-items-center gap-2">
                                                            <FontAwesomeIcon icon="fa fa-money-check"/>
                                                            {item.transaction_type}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={item.status === 'success' ? 'badge-active' : 
                                                                (item.status === 'failed' ? 'badge-failed' : 
                                                                (item.status === 'used' ? 'badge-used' : 'badge-pending'))}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        item.status === 'success' ? "#008000" : 
                                                                        (item.status === 'failed' ? "#EB5757" : 
                                                                        (item.status === 'used' ? "#828282" : "#FAB941"))
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {item.status}
                                                                </span>
                                                            </Badge> 
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => selectTransaction(item.reference)}
                                                                    >
                                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="text-danger d-flex align-items-center gap-1"
                                                                        onClick={ () => selectTransaction(item.reference) }
                                                                    >
                                                                        <i className="lni lni-question-circle"></i> Report Transaction
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        : 
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                        </Table>
                        </Card.Body>
                    </Card>
                    {
                        paycodes?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>

                {/* Widget modal */}
                <Modal
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show_widget}
                    backdrop="static"
                >
                    {
                        widget_state === 'second' ? (
                            <Modal.Header className="text-sm p-2">How it works</Modal.Header>
                        ) : null
                    }
                    <Modal.Body className="py-3 px-4">
                        {
                            widget_state === 'second' ? (
                                <div className="second">
                                    <ol className="ps-1">
                                        <li className="text-muted text-xs mb-1">
                                            Get your paycode in USD or CAD
                                        </li>
                                        <li className="text-muted text-xs mb-1">
                                            Apply paycode, your wallet will be funded
                                        </li>
                                        <li className="text-muted text-xs mb-1">
                                            Sell your USD or CAD to Naira
                                        </li>
                                        <li className="text-muted text-xs mb-1">
                                            Withdraw to your designated bank account
                                        </li>
                                    </ol>
                                    <div className="mt-4 mb-3 text-center">
                                        <button 
                                            type="button" 
                                            className="btn-plain border p-2 rounded-3 border-1 text-primary bg-white text-sm w-100"
                                            onClick={ manageWidget }
                                        > I understand
                                        </button>
                                    </div>
                                </div>
                            )
                            : (
                                <div className="first text-center">
                                    <img src={fase} alt="wallet" />
                                    <h5 className="mt-3">Welcome to FasePay</h5>
                                    <p className="mt-3 text-muted text-sm">Recieve Paycode from abroad and sell to get naira</p>
                                    <div className="text-center mt-4 mb-3">
                                        <button 
                                            type="button" 
                                            className="btn-plain border p-2 rounded-3 border-1 text-primary bg-white text-sm"
                                            onClick={ () => setWidgetState('second')}
                                        > See how it works
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </Modal.Body>
                </Modal>

                <DrewsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    dismissable={true}
                    title={transaction.reference}
                >
                    <Row>
                        <Col md={6}>
                            <div className="modal-profile">
                                <ul>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Voucher Balance</small>
                                                {transaction.amount} 
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={6} className="modal-profile">
                            <ul>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Valid Till</small>
                                            
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </DrewsModal>

                <DrewsModal
                    show={showBalance}
                    onHide={() => setShowBalance(false)}
                    size="md"
                    dismissable={true}
                    title="Check PayCode Balance"
                >
                    <Form>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Enter PayCode</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="PayCode"
                                    name="paycode"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    minLength={6}
                                    maxLength={6}
                                />
                                {
                                    errors['paycode'] && 
                                    <Form.Text className="text-danger">{errors['paycode']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret_word"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret_word'] && 
                                    <Form.Text className="text-danger">{errors['secret_word']}</Form.Text>
                                }
                            </Form.Group>
                                <p className="text-danger text-sm">
                                    {errorMessage}
                                </p>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={paycodeBalance}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Check Balance <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={genVoucher}
                    onHide={() => setGenVoucher(false)}
                    size="md"
                    dismissable={true}
                    title="Generate PayCode"
                >
                    <Form>
                        <p className="text-sm">Generate new paycode.</p>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="0.00"
                                    name="amount"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                />
                                {
                                    errors['amount'] && 
                                    <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Quantity</Form.Label>
                                <Form.Control
                                    type="number"
                                    required
                                    placeholder="e.g: 1"
                                    name="qty"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    min={1}
                                />
                                {
                                    errors['qty'] && 
                                    <Form.Text className="text-danger">{errors['qty']}</Form.Text>
                                }
                            </Form.Group>
                            
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret'] && 
                                    <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                }
                            </Form.Group>
                                <p className="text-danger text-sm">
                                    {errorMessage}
                                </p>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={createPayCode}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Generate PayCode <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={showForm}
                    onHide={() => setForm(false)}
                    size="md"
                    dismissable={true}
                    title="Apply PayCode"
                >
                    <Form>
                        <p className="text-sm">New Paycode will be added to your wallet automatically.</p>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">PayCode <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="PayCode"
                                    name="paycode"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    minLength={6}
                                    maxLength={6}
                                />
                                {
                                    errors['paycode'] && 
                                    <Form.Text className="text-danger">{errors['paycode']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret_word"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret_word'] && 
                                    <Form.Text className="text-danger">{errors['secret_word']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Currency/Amount <span className="text-danger">*</span> </Form.Label>
                                <div className="d-flex">
                                    <div className="w-25">
                                        <Form.Select 
                                            name="currency"
                                            required
                                            onChange={handleInput}
                                            onKeyUp={handleValidation}
                                        >
                                            <option value="">Select</option>
                                            <option value="NGN">NGN</option>
                                            <option value="USD">USD</option>
                                            <option value="CAD">CAD</option>
                                            <option value="EUR">EUR</option>
                                            <option value="GBP">GBP</option>
                                        </Form.Select>
                                    </div>
                                    <div className="ms-2 w-75">
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="0.00"
                                            name="amount"
                                            onChange={handleInput}
                                            onKeyUp={handleValidation}
                                            value={payload.price}
                                        />
                                    </div>
                                </div>
                                {
                                    errors['amount'] && 
                                    <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                }
                            </Form.Group>
                                <p className="text-danger text-sm">
                                    {errorMessage}
                                </p>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                className="d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={applyPaycode}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Apply PayCode <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={fxForm}
                    onHide={() => setFxForm(false)}
                    size="md"
                    dismissable={true}
                    title="Sell USD/CAD"
                >
                    <Form>
                        <div className="d-flex justify-content-between text-sm">
                           <p>USD Wallet Balance: {formatCurrency(wallet?.usd_amount, 'USD').with_currency}  </p> 
                            
                           <p>Wallet Balance: {formatCurrency(wallet?.amount).with_currency}</p>
                        </div>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Amount</Form.Label>
                                <div className="d-flex">
                                    <div className="w-25">
                                        <CurrencyDropdown value={payload.currency}  onChange={ (e) => handleCurrencySelect(e, 'currency') } />
                                    </div>
                                    <div className="ms-2 w-75">
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="0.00"
                                            name="amount"
                                            onChange={handleInput} 
                                        />
                                    </div>
                                </div>
                                <p className="text-secondary text-xs font-bold m-0 mb-3">
                                    Minimum amount you can sell: $10 or 10 CAD
                                </p>
                                {
                                    errors['amount'] && 
                                    <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                }
                            </Form.Group>

                            <div className="divider custom-divider">
                                <img src={rotateKnob} alt="knob" className="knob" />
                            </div>

                            <Form.Group as={Col} md="12" className="mb-3 mt-4">
                                <Form.Label className="mb-0">You will get</Form.Label>
                                <div className="d-flex">
                                    <div className="w-25">
                                        <CurrencyDropdown onChange={ (e) => handleCurrencySelect(e, 'to_currency') } />
                                    </div>
                                    <div className="ms-2 w-75">
                                        <Form.Control
                                            type="text"
                                            required
                                            readOnly
                                            placeholder="0.00"
                                            name="to_amount"
                                            value={ parseFloat(conversion.amount) > 0 ? formatCurrency(conversion.amount).formatted : ''}
                                        />
                                    </div>
                                </div>
                            </Form.Group>


                            <p className="text-danger text-sm">
                                {errorMessage}
                            </p>

                            <div className="bg---primary-faint text-primary rounded-16 mt-1 mb-3 py-2 w-75 m-auto">
                                <p className="m-0 text-sm">
                                    <FontAwesomeIcon icon={'info-circle'} />
                                   <span className="text-xs font-bold ms-1">Conversion rate $1 = {formatCurrency(usd_to_nara?.rate).with_currency}, 1CAD = {formatCurrency(cad_to_nara?.rate).with_currency} </span> 
                                </p>
                            </div>

                            <Form.Group 
                                as={Col} 
                                md="12" 
                                className="d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={sellFx}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Sell {payload.currency} <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>


                {/* Wallet withdrawal modal */}
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={withdrawal}
                    onHide={() => setWithdrawal(false)}
                    backdrop="static"
                >
                    <Modal.Header closeButton>Withdraw Funds</Modal.Header>
                    <Modal.Body className="p-4">
                        <Form>
                            <Row>
                                <Form.Group as={Col} md={12} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Account Name</Form.Label>
                                    <Form.Control
                                        readOnly
                                        type="text"
                                        placeholder="Account Name"
                                        name="account_name"
                                        value={wallet?.account_name}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={12} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Account Number</Form.Label>
                                    <Form.Control
                                        readOnly
                                        type="text"
                                        placeholder="Account Number"
                                        name="account_number"
                                        value={wallet?.account_number}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={12} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Bank</Form.Label>
                                    <Form.Control
                                        readOnly
                                        type="text"
                                        placeholder="Bank"
                                        name="bank_name"
                                        value={wallet?.bank_name}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={12} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Amount(NGN)</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="₦0.00"
                                        name="amount"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                    />
                                    {
                                        errors['amount'] && 
                                        <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                    }
                                    <p className="m-0 text-secondary">
                                        <small>Maximum Limit: {formatCurrency(2000000).with_currency}</small>
                                    </p>
                                </Form.Group>
                                <Form.Group as={Col} md={12} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Transaction Fee</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="₦0.00"
                                        name="total_amount"
                                        disabled
                                        value={fee}
                                    />
                                    <p className="m-0 text-secondary">
                                        <small>{charges}% fees on each withdrawal capped @ {formatCurrency(2500).with_currency}</small>
                                    </p>
                                </Form.Group>
                                <Form.Group>
                                    <p>4-Digit Transaction PIN</p>
                                    <div className="otp-boxes w-50">
                                        {
                                            Array.from({length: 4}, (item, indexx) => (
                                                <input 
                                                    type="text" 
                                                    maxLength={1} 
                                                    minLength={1} 
                                                    className="box" 
                                                    key={indexx}
                                                    onChange={ (e) => handleOtpInputs(indexx, e) }
                                                    ref={ inputRefs[indexx] }
                                                />
                                            ))
                                        }
                                    </div>
                                    <small className="mt-1 text-muted">Enter wallet PIN</small>
                                </Form.Group>
                            </Row>
                            <Form.Group className="mt-4">
                                <Button 
                                    type="button"
                                    variant="primary" 
                                    className="form-control" 
                                    size="lg"
                                    onClick={sendWithrawalRequest}
                                    disabled={Object.keys(errors).length > 0 || pinValue.join("").length < 4}
                                >
                                    Withdraw funds <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default FasePay;